// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer className="footer has-text-centered container">
    <div className="columns">
      <div className="column">
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  // siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  // siteTitle: ``,
}

export default Footer
